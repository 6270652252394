import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Spinner } from 'supplier-portal-shared-components';

const BASENAME = '/demo';

const ExamplePage = lazy(() => import('pages/ExamplePage/ExamplePage'));
const AnotherDemoPage = lazy(() => import('pages/AnotherDemoPage/AnotherDemoPage'));
const PerformanceTestPage = lazy(() => import('pages/PerformanceTestPage/PerformanceTestPage'));
const ComponentInteraction = lazy(
  () => import('pages/ComponentInteractionTestPage/ComponentInteractionPage')
);
const AuditLogTestPage = lazy(() => import('pages/AuditLogTest/AuditLog'));

const ComponentInteractionPure = lazy(
  () => import('pages/ComponentInteractionTestPage/ComponentInteractionPurePage')
);

const Router = (): JSX.Element => {
  return (
    <BrowserRouter>
      <Suspense fallback={<Spinner show dataTest="transition-spinner" />}>
        <Switch>
          <Route exact path={`${BASENAME}`} component={ExamplePage} />
          <Route exact path={`${BASENAME}/another-example`} component={AnotherDemoPage} />
          <Route exact path={`${BASENAME}/performance-test`} component={PerformanceTestPage} />
          <Route
            exact
            path={`${BASENAME}/component-interaction`}
            component={ComponentInteraction}
          />
          <Route
            exact
            path={`${BASENAME}/component-interaction-pure`}
            component={ComponentInteractionPure}
          />
          <Route exact path={`${BASENAME}/audit-log-test`} component={AuditLogTestPage} />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
};
export default Router;
