import { createFeatureToggleServiceV2 } from 'admin-portal-shared-services';
import React from 'react';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';
import App from './App';

createFeatureToggleServiceV2('admin-portal-demo-mfe', '2haJLxsNaavmrMAFAJQHw');

const reactLifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: App,
});

export const { bootstrap, mount, unmount } = reactLifecycles;
