import {
  Language,
  useAppBarConfigurationService,
  useLanguageService,
} from 'admin-portal-shared-services';
import { useStore } from 'effector-react';
import React, { ReactNode, useEffect } from 'react';
import { RawIntlProvider } from 'react-intl';
import intlProvider from '../../i18n/provider';

export interface SideBarItem {
  id: string | number;
  text: string;
  icon: React.ElementType;
  path: string;
}

export type FixedLinkItem = {
  action: string | (() => void) | null;
  hide: boolean;
};
export interface FixedLinks {
  getHelp: FixedLinkItem;
  privacy: FixedLinkItem;
  termsOfService: FixedLinkItem;
}
interface AppbarConfigProps {
  children: ReactNode;
  logo: ReactNode;
  hasSideBar?: boolean;
  supportedLanguages?: Array<Language>;
  sidebarItems: Array<SideBarItem>;
  fixedLinks: FixedLinks;
}

const AppbarConfig = ({
  children,
  logo,
  hasSideBar = false,
  supportedLanguages = [],
  sidebarItems,
  fixedLinks,
}: AppbarConfigProps): JSX.Element => {
  const service = useAppBarConfigurationService();
  const languageService = useLanguageService();

  const languageStore = languageService.getStore();
  const { selectedLanguage } = useStore(languageStore);
  const intl = intlProvider(selectedLanguage?.abbreviation);

  useEffect(() => {
    service.setLogo(logo);
    service.setHasSidebar(hasSideBar);
    service.setSidebarItems(sidebarItems);
    service.setFixedLinks(fixedLinks);
    service.setLogoAlt('bees logo demo-mfe');

    languageService.setSupportedLanguages(supportedLanguages);
  }, [logo, hasSideBar, supportedLanguages, sidebarItems, fixedLinks, service, languageService]);

  return <RawIntlProvider value={intl}>{children}</RawIntlProvider>;
};

export default AppbarConfig;
