import DesignTokensProvider from '@admin-portal-shared-components/design-tokens-provider';
import { createGenerateClassName, CssBaseline, StylesProvider } from '@material-ui/core';
import BathtubIcon from '@material-ui/icons/Bathtub';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import { Language } from 'admin-portal-shared-services';
import React from 'react';
import beesLogo from './assets/customLogo.svg';
import AppbarConfig from './components/AppbarConfig';
import Router from './Router';

const mockListMenu = [
  {
    id: 1,
    text: 'Dashboard',
    icon: EqualizerIcon,
    path: '/demo/dashboard',
  },
  {
    id: 2,
    text: 'Products',
    icon: LocalOfferIcon,
    path: '/demo/products',
  },
  {
    id: 3,
    text: 'Orders',
    icon: EqualizerIcon,
    path: '/demo/orders',
  },
  {
    id: 4,
    text: 'Inventory',
    icon: LocalOfferIcon,
    path: '/demo/inventory',
  },
  {
    id: 5,
    text: 'Billing',
    icon: EqualizerIcon,
    path: '/demo/billing',
  },
  {
    id: 6,
    text: 'Distribution centers',
    icon: LocalOfferIcon,
    path: '/demo/distribution-centers',
  },
  {
    id: 7,
    text: 'Another Example Page',
    icon: BathtubIcon,
    path: '/demo/another-example',
  },
];

const mockLanguages: Array<Language> = [
  {
    name: 'English',
    abbreviation: 'en-US',
  },
  {
    name: 'Spanish',
    abbreviation: 'es-CO',
  },
  {
    name: 'Portuguese',
    abbreviation: 'pt-BR',
  },
];

const fixedLinksMock = {
  getHelp: {
    action: '/help',
    hide: false,
  },
  privacy: {
    action: '/privacy',
    hide: false,
  },
  termsOfService: {
    action: '/terms-of-service',
    hide: false,
  },
};

function App(): JSX.Element {
  const generateClassName = createGenerateClassName({
    productionPrefix: 'admin-portal-demo-beta-mfe',
    seed: 'admin-portal-demo-beta-mfe',
  });
  return (
    <AppbarConfig
      logo={beesLogo}
      hasSideBar
      supportedLanguages={mockLanguages}
      sidebarItems={mockListMenu}
      fixedLinks={fixedLinksMock}
    >
      <DesignTokensProvider>
        <CssBaseline />
        <StylesProvider generateClassName={generateClassName} injectFirst>
          <Router />
        </StylesProvider>
      </DesignTokensProvider>
    </AppbarConfig>
  );
}

export default App;
