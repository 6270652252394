import { createIntl, createIntlCache, defineMessages, IntlShape } from 'react-intl';

export interface MessageMap {
  [key: string]: { [key: string]: string };
}

const DEFAULT_LANGUAGE = 'en-US';
const MESSAGES: MessageMap = {
  'en-US': {
    text1: 'You can translate this text.',
    text2: 'This one too!',
  },
  'pt-BR': {
    text1: 'Você pode traduzir este texto.',
    text2: 'Este aqui também!',
  },
  'es-CO': {
    text1: 'Puedes traducir este texto',
    text2: '¡Este también!',
  },
};

const intlProvider = (locale: string = DEFAULT_LANGUAGE): IntlShape => {
  const cache = createIntlCache();
  return createIntl(
    {
      locale,
      messages: defineMessages(flattenObject(MESSAGES[locale])),
    },
    cache
  );
};

/* eslint-disable @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any */
export const flattenObject = (ob: any): any => {
  const toReturn: { [key: string]: any } = {};

  for (const i in ob) {
    if (typeof ob[i] === 'object' && ob[i] !== null) {
      const flatObject = flattenObject(ob[i]);
      // eslint-disable-next-line guard-for-in
      for (const x in flatObject) {
        toReturn[`${i}.${x}`] = flatObject[x];
      }
    } else {
      toReturn[i] = ob[i];
    }
  }
  return toReturn;
};
/* eslint-enable */

export default intlProvider;
